import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/project.css";
import portfolioHeader from "../../images/portfolioHeader.png";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

/*
function openGithubProceduralPostIt() {
  window.open(
    "https://github.com/johannagranstroem/TDDD27-Advanced-Web-Programming",
    "_blank"
  );
}*/

const portfolioPage = () => (
  <Main>
    <SEO title="My Portfolio" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Portfolio" src={portfolioHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">JOHANNA GRANSTRÖM</p>
          <p class="project-header-text-grey">MY PORTFOLIO</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Languages</p>
        <p class="project-bulletpoint-text"> &#9642; JavaScript</p>
        <p class="project-bulletpoint-text"> &#9642; CSS</p>
        <p class="project-bulletpoint-text"> &#9642; HTML</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Ongoing</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          My portfolio is an ongoing project which I made to show my potential
          employees my work. I wanted to make a minimalistic but yet playful
          portfolio to show off my school projects. I see this project as
          another way for me to get knowledge within web development and design.
          I also see this as an opportunity to let my personality shine through
          by sharing my interest.
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
);

export default portfolioPage;
